/* TransactionsToastList */
.dapp-core-ui-component .toast-messages .toast {
    display: block;
    flex-basis: 4rem;
    width: 100%;
    max-width: 28.875rem;
    transition: transform 0.3s ease-in-out;
    transform: translateX(120%);
    background-color: #fff;
    border-radius: 0.5rem;
    border-color: rgb(68, 67, 67);
    background-color: rgb(31, 27, 27);
}

/* Login buttons */
.dapp-core-ui-component-2_noExtensionButtonContent {
  color: #fff;
  min-width: 200px;
  background-color: #1b46c2;
  border-color: #1b46c2;
  border-radius: 0.25rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  margin-top: -4px;
}
.dapp-core-ui-component .btn-primary {
  color: #fff;
  min-width: 200px;
}
.dapp-core-ui-component .btn-primary:hover {
  color: #fff;
}
.dapp-core-ui-component .btn-primary:focus, .dapp-core-ui-component .btn-primary.focus {
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(61, 98, 203, 0.5); 
}
.dapp-core-ui-component .btn-primary.disabled, .dapp-core-ui-component .btn-primary:disabled {
  color: #fff;
}
.dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled).active,
.show > .dapp-core-ui-component .btn-primary.dropdown-toggle {
  color: #fff;
}
.dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .dapp-core-ui-component .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(61, 98, 203, 0.5); 
}

/* Modal connect section */
.dapp-core-ui-component .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.64);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.64);
  border-radius: 0.25rem;
  padding: 10px;
}
.dapp-core-ui-component .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: rgba(224, 213, 213, 0.2);
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
}
body {
  background: rgba(0, 0, 0);
}

/* Countdown timer */
.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.75rem;
  padding: 1rem 1rem 2rem 1rem;
  /* border: 2px solid #ebebeb; */
  border-radius: 0.45rem;
  text-decoration: none;
  color: white;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 1rem 1.75rem 0 1.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}


.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.center-countdown{
  margin-top: 20px;
  text-align: center;
}

.seedsale-text{
  font-size: 35px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}
.token-text-type {
  font-size: 75px;
  font-weight: bold;
}
.account-info-custom{
  border: 2px solid lightskyblue;
  margin-right: 20px;
  padding: 0 15px 2px 15px;
  border-radius: 5%;
  font-size: 11px;
  background-color: rgba (255, 255, 255, 0.08);
}
.date-time-text{
  margin-top: 25px;
}
.popover-login{
  border: 1px solid white;
  border-radius: 10px;
  background-color: #1b46c2;;
  color: white;
}
.css-p7yt37 {
  width: 10rem;
}
.cursor-pointer{
  cursor: pointer;
}
.chakra-ui-dark{
  background-image: url(https://x-launcher.com/wp-content/uploads/2022/03/roadmap4-scaled.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* */
@keyframes moveup {
  0%   {opacity: 0; transform: translateY(12px);}
  100%  {opacity: 1; transform: translateY(0px);}
}

@keyframes movedown {
  0%   {opacity: 1; transform: translateY(0px);}
  100%  {opacity: 0; transform: translateY(-12px);}
}

.odometerStart{
  display: inline-block;
  animation-name: moveup 0.25s;
  animation: moveup 0.25s;
  -webkit-animation: moveup 0.25s;
  animation-fill-mode: forwards;
}
.odometerEnd{
  display: inline-block;
  animation-name: movedown 0.25s;
  animation: movedown 0.25s;
  -webkit-animation: movedown 0.25s;
  animation-fill-mode: forwards;
}

.odometer-block{
  display: inline-block;
  justify-content: center;
  align-items: center;
}
.custom-ctt-numbers{
  font-size: 30px;
}
.custom-ctt-numbers2{
  color: lightskyblue;
}
.custom-ctt-numbers3{
  color: rgb(0, 253, 97);
}
.dapp-core-ui-component-2{
  color: white;
  margin-right: 10px;
}
.svg-inline--fa {
  margin-left: 5px;
  margin-top: -3px;
}
.css-zeci1g {
  margin-inline-start: 1rem;
}
.css-1vv4owy {
  margin-inline-start: 1rem;
}
.css-wkrk1s {
  margin-inline-start: 1rem;
}
.elrond-logo{
  width: 52px;
  height: 43px;
}

/* Leder modal */
.dapp-core-ui-component .modal-content {
  margin-bottom: 270px;
  overflow-y: visible;
}